
@font-face {
    font-family: 'Sequel';
    src: url('../public/fonts/Sequel.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 47.4% 11.2%;
        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;
        --popover: 0 0% 100%;
        --popover-foreground: 222.2 47.4% 11.2%;
        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;
        --card: 0 0% 100%;
        --card-foreground: 222.2 47.4% 11.2%;
        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;
        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;
        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;
        --destructive: 0 100% 50%;
        --destructive-foreground: 210 40% 98%;
        --ring: 215 20.2% 65.1%;
        --radius: 0.5rem;
    }

    .dark {
        --background: 224 71% 4%;
        --foreground: 213 31% 91%;
        --muted: 223 47% 11%;
        --muted-foreground: 215.4 16.3% 56.9%;
        --accent: 216 34% 17%;
        --accent-foreground: 210 40% 98%;
        --popover: 224 71% 4%;
        --popover-foreground: 215 20.2% 65.1%;
        --border: 216 34% 17%;
        --input: 216 34% 17%;
        --card: 224 71% 4%;
        --card-foreground: 213 31% 91%;
        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 1.2%;
        --secondary: 222.2 47.4% 11.2%;
        --secondary-foreground: 210 40% 98%;
        --destructive: 0 63% 31%;
        --destructive-foreground: 210 40% 98%;
        --ring: 216 34% 17%;
        --radius: 0.5rem;
    }



    .black {
        @import url('file:///G:\Desktop\SaaS\software-frontend\src\index.css');
        --background: 0 0% 0%;
        --foreground: 0 0% 100%;
        --muted: 0 0% 0%;
        --muted-foreground: 0 0% 63%;
        --accent: 0 0% 0%;
        --accent-foreground: 0 0% 100%;
        --popover: 0 0% 0%;
        --popover-foreground: 0 0% 100%;
        --border: 0 0% 10%;
        --input: 0 0% 10%;
        --card: 0 0% 0%;
        --card-foreground: 0 0% 100%;
        --primary: 0 0% 100%;
        --primary-foreground: 0 0% 0%;
        --secondary: 0 0% 0%;
        --secondary-foreground: 0 0% 100%;
        --destructive: 0 85% 60%;
        --destructive-foreground: 0 0% 100%;
        --ring: 0 0% 0%;
        --radius: 0.5rem;
    }

}



.scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

    .scrollbar-hide::-webkit-scrollbar {
        display: none; /* Chrome, Safari and Opera */
    }



@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-foreground;
        font-feature-settings: "rlig" 1, "calt" 1;
        font-family: 'Sequel', sans-serif;
    }
}



